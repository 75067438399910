import clsx from 'clsx';
import styles from './Arrows.module.scss'

const Arrows = ({isSmall = false, isRed = false, className}) => (
  <div className={clsx(styles.arrows, isSmall ? styles.arrowsSmall : styles.arrowsBig, isRed ? styles.red : "", className)}>
    <span className={clsx(styles.arrow, styles.third)} />
    <span className={clsx(styles.arrow, styles.second)} />
    <span className={clsx(styles.arrow)} />
  </div>
)

export default Arrows;