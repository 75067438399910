export const declOfNum = (number, titles) => {
  const cases = [ 2, 0, 1, 1, 1, 2 ]

  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[ number % 10 < 5 ? number % 10 : 5 ]
  ]
}

export const formatNumberForUi = (number) => {
  number += '';
  const x = number.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? '.' + x[1]?.slice(0,2) : '';
  const rgx = /(\d+)(\d{3})/;
  let iterationCount = 0;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + (iterationCount === 0 ? '’' : '‘') + '$2');
    iterationCount += 1
  }
  return x1 + x2;
}