import styles from './Navigation.module.scss'
import Button from '../base/Button/Button'
import Text from '../base/Text/Text'
import { useApp } from '../../context/AppProvider'
import Icon from "../Icon/Icon";

const Navigation = () => {
  const { showScreen, setShowScreen } = useApp()

  return (
    <nav id="no-user-select" className={styles.nav}>
      <div className={styles.navInner}>
        <Button
          onClick={() => showScreen !== 'tap' && setShowScreen('tap')}
          variant="iconUp"
        >
          <Icon name="coins" color={showScreen === 'tap' ? '#FD9001' : '#9CDB8B'}/>
          <Text variant="textS" color={showScreen === 'tap' ? 'darkOrange' : 'green'}>Tap</Text>
        </Button>
        <Button
          onClick={() => showScreen !== 'tasks' && setShowScreen('tasks')}
          variant="iconUp"
        >
          <Icon name="document" color={showScreen === 'tasks' ? '#FD9001' : '#9CDB8B'}/>
          <Text variant="textS" color={showScreen === 'tasks' ? 'darkOrange' : 'green'}>Targets</Text>
        </Button>
        <Button
          onClick={() => showScreen !== 'farm' && setShowScreen('farm')}
          variant="iconUp"
        >
          <Icon name="farm" color={showScreen === 'farm' ? '#FD9001' : '#9CDB8B'}/>
          <Text variant="textS" color={showScreen === 'farm' ? 'darkOrange' : 'green'}>Farm</Text>
        </Button>
        <Button
          onClick={() => showScreen !== 'team' && setShowScreen('team')}
          variant="iconUp"
        >
          <Icon name="team" color={showScreen === 'team' ? '#FD9001' : '#9CDB8B'}/>
          <Text variant="textS" color={showScreen === 'team' ? 'darkOrange' : 'green'}>Tribe</Text>
        </Button>
      </div>
    </nav>
  )
}

export default Navigation
