import styles from './Farm.module.scss'
import Text from '../../base/Text/Text'
import Button from '../../base/Button/Button'
import { useEffect } from 'react'
import { useFarmScreen } from './hooks'
import useApi from '../../../api/rest'
import { useApp } from '../../../context/AppProvider'
import { TOKEN_NAME } from '../../../constants'
import clsx from "clsx";
import {formatNumberForUi} from "../../../utils/strings";
import ShineTokenName from '../../base/ShineTokenName/ShineTokenName'

const Farm = () => {
  const {
    userInfo, setUserInfo, isClaimFarmStarted,
    setIsClaimFarmStarted, setShowSuccessModal,
    setSuccessModalData
  } = useApp()
  const { startFarm, claimFarm } = useApi()
  const data = useFarmScreen()

  useEffect(() => {
    if (data?.farmStartedAt) {
      setIsClaimFarmStarted(true)
    }
  }, [data?.farmStartedAt])

  const handleStartFarm = () => {
    startFarm()
      .then((data) => {
        const { farmReward , farmStartedAt } = data?.data || {}

        if (farmReward && farmStartedAt) {
          setUserInfo({
            ...userInfo,
            farmReward,
            farmStartedAt,
          })
        }
      })
  }

  const handleClaimFarm = () => {
    claimFarm()
      .then((data) => {
        const { balance, claimedBalance } = data?.data || {}

        if (balance) {
          setUserInfo({
            ...userInfo,
            farmReward: 0,
            farmStartedAt: undefined,
            balance
          })

          setShowSuccessModal(true)
          setSuccessModalData({
            reward: <Text variant="h1" weight="bold">
              +{formatNumberForUi(claimedBalance)} <ShineTokenName />
            </Text>,
            description: <Text variant="textL">
              Stay bright, keep the fight,<br/>In every challenge, find the light.<br/>Earn more {TOKEN_NAME}!
            </Text>
          })
        }
      })
      .finally(() => {
        setIsClaimFarmStarted(false)
      })
  }

  const buttonState = isClaimFarmStarted ? data.isClaimAllowed ? "claim" : "farming" : "start";

  return (
    <div className={styles.root}>
      <div className={styles.imageWrapper}>
        <div className={clsx(styles.shine, buttonState !== "start" ? styles.shineSmall : "")} />
        <img
          src="/img/farm.png"
          width="360"
          height="370"
          alt=""
          className={clsx(styles.image, buttonState !== "start" && data.farmedReward ? styles.imageActive : "")}
        />
      </div>
      <div className={styles.headerWrap}>
        <Text variant="h2" weight="bold" tag="p" className={styles.text}>
          Farm <Text variant="h2" weight="bold" color="gradient">{TOKEN_NAME}</Text> tokens
          </Text>
      </div>
      <div>
        <Text variant="textXL" weight="bold" tag="p" className={styles.text}>
          Boost your game with token farming!<br/>Earn {TOKEN_NAME} and keep the farm rocking!
        </Text>
      </div>
      {buttonState !== "start" && data.farmedReward ? (<div className={styles.balanceContainer}>
        <Text variant="textL" weight="bold" color="green">
          Farming era reward
        </Text>
        <div className={styles.balance}>
          <Text variant="h1" weight="bold">
            {data.farmedReward}
          </Text>
          {' '}
          <ShineTokenName />
        </div>
      </div>) : null}
      <div className={styles.buttonContainer}>
        {
          buttonState === "farming"
          ? (<Button
            variant="filledSec"
            disabled={true}
            className={styles.button}
          >
            <Text variant="textXL" weight="bold" color="green">Current farming era: {data.counter}</Text>
          </Button>) 
          : buttonState === "claim" 
          ? (<Button
            variant="filledSec"
            onClick={handleClaimFarm}
            disabled={!data.isClaimAllowed}
            className={styles.button}
          >
            <Text variant="textXL" weight="bold">Claim</Text>
          </Button>) 
          : (<Button
            variant="filledSec"
            onClick={handleStartFarm}
            disabled={data.farmStartedAt}
            className={styles.button}
          >
            <Text variant="textXL" weight="bold">Start farming era</Text>
          </Button>)
        }
      </div>
    </div>
  )
}

export default Farm
