import React, { useState } from 'react';
import styles from './UpgradeModal.module.scss'
import Text from '../base/Text/Text'
import Button from '../base/Button/Button'
import clsx from "clsx";

import { TOKEN_NAME } from '../../constants'
import Loader from "../Loader/Loader";
import useApi from '../../api/rest';
import { useApp } from '../../context/AppProvider';
import Icon from '../Icon/Icon';

export const UpgradeModal = ({isOpen, setIsOpen}) => {
  const [isUpgradeStarted, setIsUpgradeStarted] = useState(false);
  const {upgradeDrum} = useApi();
  const {
    userInfo = {},
    setUserInfo
  } = useApp()

  const handleUpgrade = () => {
    setIsUpgradeStarted(true);

    upgradeDrum()
      .then((response) => {
        if (response.status !== 'error') {
          setUserInfo(response)
          setIsOpen(false, true);
        }
      })
      .finally(() => {
        setIsUpgradeStarted(false)
      })
  }

  return (
    <div className={clsx(styles.modalWrapper, isOpen ? styles.modalOpened : "")} onClick={() => setIsOpen(false)}>
      <div className={styles.modalContent} onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
      }}>
        <div className={styles.modalContentInner}>
          <Text variant="h2" weight="bold" className={styles.header}>New Drum available
          <button type="button" onClick={() => setIsOpen(false)} className={styles.closeButton}>
            <Icon width={20} height={20} name="close" className={styles.closeIcon}/>
          </button></Text>
          <img src="/img/drum-upgrade.png" alt="Drum" className={styles.modalImage} />
          <Text variant="h1" color="gradient" weight="bold">Buffalo Drum</Text>
          <p className={styles.modalDescription}>Tap faster to earn more DRUMs.<br/>Boost your rewards up to 3x!</p>
          <div className={styles.modalAction}>
            <div className={styles.modalInfo}>
              <Text variant="textXL" weight="bold">
                Total
              </Text>
              <div className={styles.modalAmount}>
                <Text variant="textXL" weight="bold">
                  -3’000
                </Text>
                {' '}
                <Text variant="textXL" color="gradient" weight="bold">{TOKEN_NAME}</Text>
              </div>
            </div>
            <Button
              variant="filledBull"
              size="sizeL"
              onClick={handleUpgrade}
              disabled={(userInfo && userInfo.balance < 3000) || isUpgradeStarted}
            >
              {isUpgradeStarted &&
                <Loader disabled={isUpgradeStarted}/>
              }
              <Text variant="textXL" weight="bold">
                {userInfo && userInfo.balance < 3000 ? `You need ${3000 - userInfo.balance} more Drums` : "Upgrade"}
              </Text>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const UpgradeModalContext = React.createContext(null);

export const UpgradeModalProvider = ({
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    setShowSuccessModal,
    setSuccessModalData,
  } = useApp()

  const handleModalState = (val, withSuccess = false) => {
    setIsOpen(val);
    if(val) {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0)
      }
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    if (withSuccess) {
      setShowSuccessModal(true)
      setSuccessModalData({
        title: "Congrats!",
        description: <Text variant="textL">
          New drum unlocked! The faster you tap, the greater your rewards!
        </Text>,
        button: "Let’s try",
      })
    }
  }
  
  return (
    <UpgradeModalContext.Provider value={{isOpen, setIsOpen: handleModalState}}>
        {children}
        <UpgradeModal isOpen={isOpen} setIsOpen={handleModalState}/>
    </UpgradeModalContext.Provider>
  );
}
