import {Fragment} from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import {useTransition, animated, config} from '@react-spring/web'

import Text from '../../base/Text/Text'
import Button from '../../base/Button/Button'

import customStyles from './TaskDescriptionModal.module.scss'


const TaskDescriptionModal = ({showModal, setShowModal, data}) => {

  const transitions = useTransition(showModal, {
    from: {opacity: 0, y: 1000},
    enter: {opacity: 1, y: 0},
    leave: {opacity: 0, y: 1000},
    config: config.default,
  })

  return (
    <>
      <Dialog.Root open={showModal} onOpenChange={setShowModal}>
        {transitions((styles, item) => {
          return item ? (
            <>
              <Dialog.Overlay forceMount asChild>
                <animated.div
                  className={customStyles.overlay}
                  style={{
                    opacity: styles.opacity,
                  }}
                >
                  <>
                    <Dialog.Content forceMount asChild>
                      <animated.div style={styles} className={customStyles.content}>
                        <div className={customStyles.heading}>
                          <Text variant="textL" weight="bold" align="center">
                            {data?.title}
                          </Text>
                        </div>
                        {data?.description ? (<div className={customStyles.steps}>
                          <Text variant="textL" weight="semiBold" color='white'>
                            {data?.description?.title}
                          </Text>
                          {data?.description?.list ? (<ul className={customStyles.list}>
                            { Object.values(data?.description?.list).map((value, index) => {
                              return (
                                <li key={index}>
                                  <Text variant="textM" weight="semiBold" color='white50'>
                                    {value}
                                  </Text>
                                </li>
                              )
                            })}
                          </ul>) : null}
                        </div>) : null}
                        <Dialog.Close asChild>
                          <Button className={customStyles.ok} variant="filledSec" size="sizeL" onClick={() => {
                            setShowModal(false)
                          }}>
                            <Text variant="textXL" weight="bold">
                              Ok
                            </Text>
                          </Button>
                        </Dialog.Close>
                      </animated.div>
                    </Dialog.Content>
                  </>
                </animated.div>
              </Dialog.Overlay>
            </>
          ) : null
        })}
      </Dialog.Root>
    </>
  )
}

export default TaskDescriptionModal