import styles from './Team.module.scss'
import { useEffect, useState } from 'react'

import { useTelegram } from '../../../context/TelegramProvider'
import { useApp } from '../../../context/AppProvider'
import useApi from '../../../api/rest'
import Text from '../../base/Text/Text'
import Button from '../../base/Button/Button'
import Loader from "../../Loader/Loader";
import { TOKEN_NAME } from '../../../constants'
import Icon from "../../Icon/Icon";
import {formatNumberForUi} from "../../../utils/strings";
import ShineTokenName from '../../base/ShineTokenName/ShineTokenName'
import clsx from "clsx";

const Team = () => {
  const { webApp } = useTelegram()
  const { userInfo = {}, setUserInfo, setShowSuccessModal, setSuccessModalData } = useApp()
  const { getChildren, claimFromChildren } = useApi()
  const [isClaimStarted, setIsClaimStarted] = useState(false)
  const [childrenData, setChildrenData] = useState({
    children: [],
    totalBalance: '0.0000',
    totalRewardsToClaim: '0.0000'
  })
  const claim = () => {
    setIsClaimStarted(true)
    claimFromChildren().then(response => {
      if (response.status === 'error') {
        // show error
        console.error(response.error)
        return
      }
      setUserInfo({
        ...userInfo,
        balance: response.data.balance
      })
      if (parseFloat(childrenData.totalRewardsToClaim) !== 0) {
        setShowSuccessModal(true)
        setSuccessModalData({
          reward: <Text variant="h1" weight="bold">
            +{formatNumberForUi(response.data.claimedBalance)} <ShineTokenName />
          </Text>,
          description: <Text variant="textL">
            Stay bright, keep the fight,<br/>In every challenge, find the light.<br/>Earn more {TOKEN_NAME}!
          </Text>
        })
      }
      getChildren().then(resp => {
        if (resp.status === 'error') {
          // show error
          console.error(resp.error)
          return
        }

        setChildrenData(resp.data)
      })
      setIsClaimStarted(false)
    })
  }
  
  const handleInviteFriends = () => {
    webApp.openTelegramLink(userInfo?.inviteUrl || '')
  }

  const handleShare = () => {
    const shareData = {
      title: userInfo?.shareDetails?.text,
      text: userInfo?.shareDetails?.text,
      // url: userInfo?.shareDetails?.url
    }

    if (webApp.platform !== 'ios') {
      shareData.url = userInfo?.shareDetails?.url
    }

    if (navigator?.share) {
      navigator?.share(shareData)
        .then(() => {
          // Handle success
          console.log('Content shared successfully');
        })
        .catch((error) => {
          // Handle error
          console.error('Failed to share content:', error);
        });
    } else {
      webApp.openTelegramLink(userInfo?.inviteUrl || '')
    }
  }

  useEffect(() => {
    getChildren().then(response => {
      if (response.status === 'error') {
        // show error
        console.error(response.error)
        return
      }

      setChildrenData(response.data)
    })
  }, [])

  const withCursor = childrenData.cursor !== undefined;

  return (
    <div className={styles.container}>
      <Text variant="h2" weight="bold">More in your tribe, more <Text variant="h2" weight="bold" color="gradient">{TOKEN_NAME}s</Text> and fun to vibe!</Text>
      <div className={styles.contentContainer}>
        <div className={styles.financeContainer}>
          <Text variant="textL" color="green" weight="bold">Total Tribe balance</Text>
          <Text variant="h1" weight="bold">{formatNumberForUi(childrenData.totalBalance)}{withCursor ? "+" : ""} <ShineTokenName /></Text>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.financeContainer}>
          <Text variant="textL" color="green" weight="bold">Your rewards</Text>
          <Text variant="h1" weight="bold">{formatNumberForUi(childrenData.totalRewardsToClaim)}{withCursor ? "+" : ""} <ShineTokenName /></Text>
          {withCursor ? null : (<Text variant="textL" className={styles.financeDescr}>10% of your friends' rewards</Text>)}
        </div>
        {withCursor ? (
          <div className={styles.progressWrapper}>
            <div className={styles.progressHeader}>
              Claimed:
            </div>
            <div className={styles.progressInfo}>
              <span>{childrenData.cursor}</span>
              <span>of {childrenData.totalChildrenCount} users</span>
            </div>
            <div className={styles.progress}>
              <div className={styles.progressInner} style={{width: `${childrenData.cursor/childrenData.totalChildrenCount*100}%`}}/>
            </div>
          </div>
        ) : null}
        <Button variant="filledSec" onClick={claim} disabled={(parseFloat(childrenData.totalRewardsToClaim) === 0 && !withCursor) || isClaimStarted} className={styles.claimButton}>
          {isClaimStarted &&
            <Loader disabled={isClaimStarted}/>
          }
          <Text variant="textXL" weight="bold">{parseFloat(childrenData.totalRewardsToClaim) === 0 && withCursor ? "Update" : "Claim"}</Text>
        </Button>
      </div>
      <div className={styles.teamWrapper}>
        <div className={styles.teamContainer}>
          <div className={styles.team}>
            <Icon name="users" width="24px" height="24px" color="white"/>
            <Text variant="textXL">Your team</Text>
          </div>
          <Text variant="textXL">
            {childrenData.totalChildrenCount ?? childrenData.children?.length} user{childrenData.children?.length !== 1 ? "s" : ""}
          </Text>
        </div>
        <div className={styles.teamList}>
          {
            childrenData
              .children
              ?.map((child, index) => (
                  <div key={index} className={styles.member}>
                    <div className={styles.wrapper}>
                      <div className={styles.name}>
                        <Icon name="user" width="16px" height="16px"/>
                        <Text variant="textXL" weight="bold">
                          {child.userName}
                        </Text>
                      </div>
                      <Text variant="textXL" weight="bold">
                        {child.balance} <Text variant="textXL" weight="bold" color="gradient">{TOKEN_NAME}</Text>
                      </Text>
                    </div>
                  </div>
                )
              )
          }
        </div>
        <div className={clsx(styles.shareWrapper, withCursor ? styles.withCursor : "")}>
          {withCursor ? (<div className={styles.shareMoreUsers}>+{childrenData.totalChildrenCount - childrenData.childrenCount} users</div>) : null}
          <div className={styles.shareWrapperInner}>
            <Button variant="filledSec" fullWidth onClick={handleInviteFriends} className={styles.inviteButton}>
              <Text variant="textXL" weight="bold">Invite friends</Text>
            </Button>

            <Button className={styles.shareButton} onClick={handleShare}>
              <Icon name="share" width="20px" height="20px" color="#FFFFFF"/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
