import {useEffect, useState} from 'react'

import Text from '../../base/Text/Text'
import { useApp } from '../../../context/AppProvider'

import useApi from '../../../api/rest'
import {TOKEN_NAME} from '../../../constants'
import {useTelegram} from '../../../context/TelegramProvider'

import styles from './Tasks.module.scss'
import {formatNumberForUi} from "../../../utils/strings";
import Task from "./components/Task";
import RegisterTask from "./components/RegisterTask";
import clsx from "clsx";

const Tasks = () => {
  const { webApp } = useTelegram()
  const { userInfo = {}, setUserInfo, setShowSuccessModal, setSuccessModalData } = useApp()
  const { startTask, claimTask, checkTask } = useApi()
  const [ tasks, setTasks] = useState({inProgress: [], completed: []})
  const [ startTaskLoading, setStartTaskLoading ] = useState(null)
  const [ claimTaskLoading, setClaimTaskLoadingTaskLoading ] = useState(false)
  const [ checkTaskLoading, setCheckTaskLoading ] = useState(null)
  const [showCompleted, setShowCompleted] = useState(false);

  const handleGoTask = (data) => {
    const { taskDetails: details, type, inviteUrl } = data

    if (type === "referral") {
      if (webApp && webApp.platform === 'ios') {
        webApp.openTelegramLink(inviteUrl)
      } else {
        window.open(inviteUrl, '_blank')
      }
    }

    if (type === "social" && details.action === 'redirect') {
      if (webApp && webApp.platform === 'ios') {
        if (details.redirectUrl.includes('t.me')) {
          webApp.openTelegramLink(details.redirectUrl)
        } else {
          webApp.openLink(details.redirectUrl)
        }
      } else {
        window.open(details.redirectUrl, '_blank')
      }
    }
  }

  const handleStartTask = (task) => {
    setStartTaskLoading(task.taskId)
    startTask(task.taskId)
      .then((data) => {
        if (data.status !== 'error') {
          setTimeout(() => {
            const updatedTasks = userInfo.tasks
            updatedTasks[task.taskId] = data

            setUserInfo({
              ...userInfo,
              tasks: updatedTasks
            })
            setStartTaskLoading(null)

            if (data.taskDetails) {
              return handleGoTask(task.taskData)
            }
          }, 1000)
        }
      }).catch((error) => {
        console.error('startTask error: ', error)

        setStartTaskLoading(null)
      })
  }

  const handleCheckTask = (taskId) => {
    setCheckTaskLoading(taskId)

    checkTask(taskId)
      .then((data) => {
        if (data.status !== 'error') {
          setTimeout(() => {
            const updatedTasks = userInfo.tasks
            updatedTasks[taskId] = data

            setUserInfo({
              ...userInfo,
              tasks: updatedTasks
            })
            setCheckTaskLoading(null)
          }, 1000)
        }
      })
      .catch((error) => {
        console.error('checkTask error: ', error)

        setCheckTaskLoading(null)
      })
  }

  const handleClaim = (taskId) => {
    setClaimTaskLoadingTaskLoading(taskId)
    claimTask(taskId)
      .then((data) => {
        const updatedTasks = userInfo.tasks
        updatedTasks[taskId] = data.task

        setUserInfo({
          ...userInfo,
          balance: data.balance,
          tasks: updatedTasks
        })
        setClaimTaskLoadingTaskLoading(null)
        setShowSuccessModal(true)
        setSuccessModalData({
          reward: <Text variant="h1" weight="semiBold">
            +{formatNumberForUi(data.claimedBalance)} <Text variant="h1" weight="semiBold" color="gradient">{TOKEN_NAME}</Text>
          </Text>,
          description: <Text variant="textL">
            Stay bright, keep the fight,<br/>In every challenge, find the light.<br/>Earn more {TOKEN_NAME}!
          </Text>
        })

      })
  }

  useEffect(() => {
    if (userInfo && userInfo.tasks) {
      let taskInProgressPre = [];
      let taskDonePre = [];

      Object.keys(userInfo.tasks).forEach((key) => {
        if (userInfo.tasks[key].state === "Claimed") {
          taskDonePre.push({
            taskId: key,
            taskData: {
              ...(userInfo.tasks[key].type === "referral" && {inviteUrl: userInfo.inviteUrl}),
              ...userInfo.tasks[key]
            }
          })
        } else {
          taskInProgressPre.push({
            taskId: key,
            taskData: {
              ...(userInfo.tasks[key].type === "referral" && {inviteUrl: userInfo.inviteUrl}),
              ...userInfo.tasks[key]
            }
          })
        }
      })
      const taskInProgress = [];
      const taskDone = [];
      SORT.forEach(el => {
        const taskIPToAdd = taskInProgressPre.find(task => task.taskId === el);
        const taskDToAdd = taskDonePre.find(task => task.taskId === el);
        if(taskIPToAdd) {
          taskInProgress.push(taskIPToAdd)
        }
        if (taskDToAdd) {
          taskDone.push(taskDToAdd);
        }
      })
      // taskInProgress.sort((a, b) => a.taskData.priority - b.taskData.priority)
      // taskDone.sort((a, b) => a.taskData.priority - b.taskData.priority)
      setTasks({inProgress: taskInProgress, completed: taskDone})
    }
  }, [userInfo])

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <Text tag="p" variant="h2" weight="bold">
          Achieve the task,<br/>earn the tribal reward!
        </Text>
        <Text tag="p" variant="textXL" weight="bold" color="gradient">
          But keep in mind, only qualified actions<br/>unlock {TOKEN_NAME} tokens! ✨
        </Text>
      </div>
      <button type="button" onClick={() => setShowCompleted(prev => !prev)} className={clsx(styles.toggleElement)}>
        <span className={clsx(styles.toggleLabel, showCompleted ? "" : styles.toggleActive)}>New</span>
        <span className={clsx(styles.toggleLabel, showCompleted ? styles.toggleActive : "")}>Completed</span>
      </button>
      <ul className={styles.taskList}>
        {(showCompleted ? tasks.completed : tasks.inProgress).map((task, index) => {
          if (task.taskId === "register_on_cex_io") {
            return (
              <RegisterTask key={index} {...{
                userData: {
                  email: userInfo.email,
                  sentEmail: userInfo?.sentEmail,
                  sentEmailCreatedAtFormatted: userInfo?.sentEmailCreatedAtFormatted
                },
                task,
                handleStartTask,
                startTaskLoading,
                handleGoTask,
                handleClaim,
                claimTaskLoading,
                handleCheckTask,
                checkTaskLoading
              }}/>
            )
          } else {
            return (
              <Task key={index} {...{
                task,
                handleStartTask,
                startTaskLoading,
                handleGoTask,
                handleClaim,
                claimTaskLoading,
                handleCheckTask,
                checkTaskLoading
              }}/>
            )
          }
        })}
      </ul>
    </div>
  )
}

export default Tasks

const SORT = [
  "subscribe_telegram",
  "follow_on_x",
  "share_tweet_on_x",
  "share_tweet_on_x_2",
  "share_post_on_facebook_2",
  "join_discord",
  "join_reddit",
  "follow_on_youtube",
  "boost_telegram_1",
  "install_wigwam_extension",
  "share_post_on_facebook",
  "join_secretpadbot",
  "invite_1_friend",
  "invite_5_friends",
  "invite_10_friends",
  "invite_20_friends",
  "invite_50_friends",
  "invite_100_friends",
  "join_cex_io",
  "share_tweet_on_twitter",
  "join_linkedin",
  "join_facebook",
  "review_chrome_webstore"
];
