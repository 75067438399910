/* eslint-disable react/no-unknown-property */
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

const initialContext = {}

export const TelegramContext = createContext(initialContext)

export const TelegramProvider = ({ children }) => {
  const [webApp, setWebApp] = useState(null)

  useEffect(() => {
    if (webApp === null) {
      const app = window.Telegram?.WebApp
      if (app) {
        app.ready()
        setWebApp(app)
      }
    }
  }, [window])

  const value = useMemo(() => {
    if (webApp) {
      return {
        webApp,
        unsafeData: webApp.initDataUnsafe,
        user: webApp.initDataUnsafe.user,
      }
    }

    return {}
  }, [webApp])

  return (
    <TelegramContext.Provider value={value}>
      {children}
    </TelegramContext.Provider>
  )
}

export const useTelegram = () => useContext(TelegramContext)
