import customStyles from './SuccessModal.module.scss'
import * as Dialog from '@radix-ui/react-dialog'
import {useTransition, animated, config} from '@react-spring/web'
import {useApp} from "../../../context/AppProvider";
import Text from "../../base/Text/Text";
import Button from "../../base/Button/Button";
import Icon from "../../Icon/Icon";
import {Fragment} from "react";


const SuccessModal = () => {
  const {showSuccessModal, setShowSuccessModal, successModalData, setSuccessModalData} = useApp()

  const transitions = useTransition(showSuccessModal, {
    from: {opacity: 0, y: 1000},
    enter: {opacity: 1, y: 0},
    leave: {opacity: 0, y: 1000},
    config: config.default,
  });

  return (
    <>
      <Dialog.Root open={showSuccessModal} onOpenChange={setShowSuccessModal}>
        {transitions((styles, item) => {
          return item ? (
            <>
              <Dialog.Overlay forceMount asChild>
                <animated.div
                  className={customStyles.overlay}
                  style={{
                    opacity: styles.opacity,
                  }}
                >
                  <>
                    <Dialog.Content forceMount asChild>
                      <animated.div style={styles} className={customStyles.content}>
                        <div className={customStyles.heading}>
                          <Icon name="success" width="60px" height="60px" className={customStyles.icon}/>
                          <Text variant="textXL" weight="bold">
                            {successModalData?.title ?? "Woo hoo!"}
                          </Text>
                        </div>
                        <div className={customStyles.rows}>
                          { successModalData && Object.entries(successModalData).map(([key, value]) => {
                            if (key === "title" || key === "button") {
                              return null;
                            }
                            return <Fragment key={key} children={value}/>
                          })}
                        </div>
                        <Dialog.Close asChild>
                          <Button variant="filledSec" className={customStyles.button} onClick={() => {
                            setSuccessModalData(null)
                            setShowSuccessModal(false)
                          }}>
                            <Text variant="textXL" weight="bold">
                              {successModalData?.button ?? "Go for more!"}
                            </Text>
                          </Button>
                        </Dialog.Close>
                      </animated.div>
                    </Dialog.Content>
                  </>
                </animated.div>
              </Dialog.Overlay>
            </>
          ) : null;
        })}
      </Dialog.Root>
    </>
  )
}

export default SuccessModal