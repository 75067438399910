import useSound from 'use-sound';
import styles from './Tap.module.scss'
import { useRef, useContext, useState, useEffect } from 'react'
import {animated, useSpring} from '@react-spring/web'

import Text from '../../base/Text/Text'
import ShineTokenName from '../../base/ShineTokenName/ShineTokenName'
import Button from '../../base/Button/Button'

import {Duration} from 'luxon'
import { TOKEN_NAME } from '../../../constants'
import useApi from '../../../api/rest'
import { useApp } from '../../../context/AppProvider'
import Icon from "../../Icon/Icon";
import Loader from "../../Loader/Loader";
import clsx from "clsx";
import Arrows from './Arrows';
import {useTelegram} from "../../../context/TelegramProvider";
import {formatNumberForUi} from "../../../utils/strings";
import {vibrate} from "../../../utils/vibrate";
import { UpgradeModalContext } from '../../UpgradeModal/UpgradeModal';


const Tap = () => {
  const [playbackRate, setPlaybackRate] = useState(0.5);
  const [play] = useSound('/drum.mp3', {playbackRate});
  const [playBull] = useSound('/drum-bull.mp3', {playbackRate});
  const { webApp } = useTelegram()
  const timer = useRef(null);
  const drumRef = useRef(null);
  const {
    userInfo = {}, setUserInfo, setShowSuccessModal, setSuccessModalData,
    tapsState, setTapsState, tapsTimeLeftInMillis
  } = useApp()
  const { claimTaps } = useApi()
  const [isClaimStarted, setIsClaimStarted] = useState(false)
  const [animateTap, setAnimateTap] = useState(false)
  const [animateClaimContainer, setAnimateClaimContainer] = useState(false)
  const [circleWidth, setCircleWidth] = useState(0);
  const [soundOn, setSoundOn] = useState(true);
  const [vibbrationOn, setVibrationOn] = useState(true);
  const upgraded = Boolean(userInfo?.superDrumMintedAt);
  const {setIsOpen} = useContext(UpgradeModalContext);

  const isDisabled = tapsState.tapsLeft === 0 || isClaimStarted;

  const resetAnimation = () => {
    const drum = drumRef.current;
    if(!drum) return;
    drum.style.animation = 'none';
    const h = drum.offsetHeight; /* trigger reflow */
    drum.style.animation = null;
  }

  const _handleTap = () => {
    if(!(tapsState.currentTapWindowFinishIn && tapsState.tapsLeft === 0) && soundOn) {
      if (upgraded) {
        playBull();
      } else {
        play();
      }
      setPlaybackRate(oldPlaybackRate => oldPlaybackRate > 1.7 ? 1.7 : playbackRate + 0.02);
    }

    resetAnimation();

    setAnimateTap(true)

    if(vibbrationOn) {
      vibrate({ duration: 400, interval: 100, count: 1 })
    }

    const newTapsLeft = tapsState.tapsLeft - 1
    const newTapsCount = tapsState.tapsCount + 1;
    let newAmount = tapsState.tapsAmount;

    if (newTapsLeft < 0) {
      setAnimateClaimContainer(true)
      setTimeout(() => {
        setAnimateClaimContainer(false)
      }, 400)
      return
    }

    if (timer.current) {
      clearTimeout(timer.current)
    }

    if (upgraded) {
      if (circleWidth + 1 > 270) {
        createParticle(upgraded, 3);
        newAmount = tapsState.amount + 3;
      } else if (circleWidth + 1 > 210) {
        createParticle(upgraded, 2);
        newAmount = tapsState.amount + 2;
      } else {
        createParticle(upgraded);
        newAmount = tapsState.amount + 1;
      }
    } else {
      createParticle();
      newAmount = tapsState.amount + 1;
    }

    setCircleWidth(prevWidth => {
      if(prevWidth < 180) {
        return 180;
      }
      if(prevWidth + 1 > 340) {
        return 340;
      }
      return prevWidth + (prevWidth > 320 ? 1 : prevWidth > 300 ? 2 : prevWidth > 240 ? 4 : 10)
    });

    setTapsState({
      ...tapsState,
      tapsLeft: newTapsLeft,
      tapsCount: newTapsCount,
      amount: newAmount,
    })

    timer.current = setTimeout(() => {
      setCircleWidth(0);
      if (soundOn) setPlaybackRate(0.5);
    }, 1000)
  }

  const handleClaim = () => {
    if (Number(tapsState.tapsCount) <= 0 || Number(tapsState.amount) <= 0) {
      return
    }

    setIsClaimStarted(true)
    claimTaps(tapsState.tapsCount, tapsState.amount)
      .then((response) => {
        if (response.status !== 'error') {
          setTapsState({
            ...tapsState,
            tapsLeft: null,
            tapsCount: 0,
            amount: 0,
          })
          setUserInfo(response)
          setShowSuccessModal(true)
          setSuccessModalData({
            reward: <Text variant="h1" weight="bold">
              +{tapsState.amount} <ShineTokenName />
            </Text>,
            description: <Text variant="textL">
              Stay bright, keep the fight,<br/>In every challenge, find the light.<br/>Earn more {TOKEN_NAME}!
            </Text>
          })
        }
      })
      .finally(() => {
        setIsClaimStarted(false)
      })
  }


  const handleInviteFriends = () => {
    try {
    webApp.openTelegramLink(userInfo?.inviteUrl || '')
    } catch (e) {
      console.error(e);
    }
  }

  const handleShare = () => {
    try {
    const shareData = {
      title: userInfo?.shareDetails?.text,
      text: userInfo?.shareDetails?.text,
      // url: userInfo?.shareDetails?.url
    }

    if (webApp.platform !== 'ios') {
      shareData.url = userInfo?.shareDetails?.url
    }

    if (navigator?.share) {
      navigator?.share(shareData)
        .then(() => {
          // Handle success
          console.log('Content shared successfully');
        })
        .catch((error) => {
          // Handle error
          console.error('Failed to share content:', error);
        });
    } else {
      webApp.openTelegramLink(userInfo?.inviteUrl || '')
    }
    } catch (e) {
      console.error(e);
    }
  }

  const [style, api] = useSpring(() => ({
    from: {
      translate: [0, 0],
      rotate: 0
    },
    config: {
      duration: 4
    }
  }));

  useEffect(() => {
    if (animateClaimContainer) {
      api.start({
        to: [
          {
            translate: [1, 1],
            rotate: 0
          },
          {
            translate: [-1, -2],
            rotate: -1,
          },
          {
            translate: [-3, 0],
            rotate: 1
          },
          {
            translate: [3, 2],
            rotate: 0
          },
          {
            translate: [1, -1],
            rotate: 1
          },
          {
            translate: [-1, 2],
            rotate: -1,
          },
          {
            translate: [-3, 1],
            rotate: 0
          },
          {
            translate: [3, 1],
            rotate: -1,
          },
          {
            translate: [-1, -1],
            rotate: 1
          },
          {
            translate: [1, 2],
            rotate: 0
          },
          {
            translate: [0, 0],
            rotate: 0,
          }
        ]
      });

      setAnimateClaimContainer(false)
    }

  }, [animateClaimContainer]);

  const hanldeSoundClick = () => {
    setSoundOn(prev => {
      localStorage.setItem("SOUND", !prev);
      return !prev;
    })
  }
  const hanldeVibrationClick = () => {
    setVibrationOn(prev => {
      localStorage.setItem("VIBRATION", !prev);
      return !prev;
    })
  }

  useEffect(() => {
    setSoundOn(localStorage.getItem("SOUND") && localStorage.getItem("SOUND") === "false" ? false : true)
    setVibrationOn(localStorage.getItem("VIBRATION") && localStorage.getItem("VIBRATION") === "false" ? false : true)
  }, [])

  const loading = !userInfo || isObjectEmpty(userInfo);

  return (
    <>
    <div id="no-user-select">
      <div className={styles.balanceContainer}>
        <Text variant="textL" weight="bold" color="green">
          Your {TOKEN_NAME} Tokens
        </Text>
        <div className={styles.balance}>
          <Text variant="h1" weight="bold">
            {userInfo && userInfo.balance ? formatNumberForUi(userInfo.balance) : 0}
          </Text>
          {' '}
          <ShineTokenName />
        </div>
      </div>
      <div className={styles.tapContainer}>
        <div className={styles.tapContainerText}>
          <Text tag="div" variant="textL" weight="bold">
            Keep tapping your drum!
            <br/>
            Nonstop fun, all day long!
          </Text>
          <Text variant="textM" tag="div" color="orange">
            ⏰ Take a breather when the timer refills,
            <br/>
            then get back to the thrill 🥁!
          </Text>
        </div>

        <div id="tapBtnContainer" className={styles.tapBtnContainer}>
          {tapsState.currentTapWindowFinishIn && tapsState.tapsLeft === 0 ? (
            <Text tag="div" color="white" weight="bold" className={styles.counter}>{Duration.fromMillis(tapsTimeLeftInMillis).toFormat("hh:mm:ss")}</Text>
          ) : null}
          <Button
            variant="icon"
            size="size0"
            onClick={_handleTap}
            // disabled={tapsState.currentTapWindowFinishIn && tapsState.tapsLeft === 0}
            className={styles.tapButton}
          >
            {!loading ? (<img
              ref={drumRef}
              src={upgraded ? "/img/drum-bull.png" : "/img/drum.png"}
              width="260"
              height="251"
              alt=""
              className={clsx(styles.tapCoin, tapsState.currentTapWindowFinishIn && tapsState.tapsLeft === 0 ? styles.tapCoinDisabled : "", animateTap && !(tapsState.currentTapWindowFinishIn && tapsState.tapsLeft === 0) ? styles.animate : '')}
              onAnimationEnd={() => setAnimateTap(false)}
            />) : null}
          </Button>
          {!isDisabled && !loading ? (
            <>
              <div className={clsx(styles.backCircle, upgraded ? styles.backCircleUpgraded : "")} style={{width: circleWidth, height: circleWidth}}/>
              <div className={clsx(styles.backShine, upgraded ? styles.backShineUpgraded : "")} />
              <Arrows isRed={upgraded} className={styles.arrowLeft} />
              <Arrows isRed={upgraded} className={styles.arrowRight} />
            </>
          ) : null}
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoActions}>
            <button type="button" onClick={hanldeSoundClick} className={clsx(styles.soundButton, !soundOn ? styles.soundButtonOff : "")}>
              <Icon name={!soundOn ? "sound-off" : "sound-on"} width={24} height={24} className={styles.buttonIcon} />
            </button>
            <button type="button" onClick={hanldeVibrationClick} className={clsx(styles.soundButton, !vibbrationOn ? styles.soundButtonOff : "")}>
              <Icon name={"vibration-on"} width={24} height={24} className={styles.buttonIcon} />
            </button>
          </div>
          <div className={clsx(styles.tapInfoLeft, {[styles.tapInfoLeftActive]: tapsState.tapsLeft > 0})}>
            {tapsState.tapsLeft === 0 ? (<Arrows isRed={upgraded} isSmall className={styles.arrowBottom} />) : null}
            <Text tag="div" variant="textL" color="white" weight="semiBold">
              {tapsState.tapsLeft ?? 0} taps left
            </Text>
            {tapsState.tapsLeft === 0 ? (<Arrows isRed={upgraded} isSmall className={styles.arrowBottom} />) : null}
          </div>
          {!loading ? upgraded ? null : <button type="button" onClick={()=>setIsOpen(true)} className={clsx(styles.drumUpgradeButton)}>
            <Icon name="drum-upgrade" width={24} height={24} />
          </button> : null}
        </div>
      </div>
      <animated.div className={clsx(styles.claimContainer, tapsState.tapsLeft === 0 && tapsState.amount !== 0 ? styles.claimContainerBorder : "", tapsState.tapsLeft !== 0 && tapsState.amount === 0 ? styles.claimContainerTap : "", tapsState.tapsLeft === 0 && tapsState.amount === 0 ? styles.claimContainerInvite : "")} style={style}>
        {
          tapsState.amount !== 0
          ? (
            <>
              <div className={styles.balance}>
                <Text variant="h3" weight="bold">
                  {tapsState.amount}
                </Text>
                <Text variant="h3" color="gradient" weight="bold">
                  {TOKEN_NAME}
                </Text>
              </div>
              <Button
                variant={upgraded ? "filledBull" : "filledSec"}
                size="sizeLMinW"
                onClick={handleClaim}
                disabled={isClaimStarted}
              >
                { isClaimStarted &&
                  <Loader disabled={isClaimStarted}/>
                }
                <Text variant="textXL" weight="bold">
                  Claim
                </Text>
              </Button>
            </>
          )
          : tapsState.tapsLeft !== 0 ? (
            <Text variant="textXL">Start tapping to Claim <Text variant="textXL" weight="bold">{TOKEN_NAME}</Text></Text>
          ) : (
            <>
              <Text variant="textM" align="left">Need more taps?<br/>Expand your tribe!</Text>
              <Button variant="filledSec" className={styles.inviteButton} onClick={handleInviteFriends}><Text variant="textXL" weight="bold">Invite</Text></Button>
              <Button className={styles.shareButton} onClick={handleShare}>
                <Icon name="share" width="20px" height="20px" color="#FFFFFF"/>
              </Button>
            </>
          )
        }
      </animated.div>
    </div>
    </>
  )
}

export default Tap

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const createParticle = (upgraded = false, tap = 1) => {
    const parent = document.querySelector("#tapBtnContainer");
    const style = document.createElement("style");
    const animationID = "id" + Math.random().toString(16).slice(2);
    const initTranslateX = randomIntFromInterval(-70, 70);
    const initScale = randomIntFromInterval(80, 140);
    const finalScale = initScale - randomIntFromInterval(10, 60);
    style.innerHTML = `
    @keyframes ${animationID} {
      0% {
        transform: translate(${initTranslateX + "px, " + randomIntFromInterval(-20, 20) + "px"}) scale(${initScale/100}) rotate(0deg);
        opacity: 1;
      }

      30% {
        opacity: 1;
      }

      100% {
        transform: translate(${initTranslateX + randomIntFromInterval(-10, 10) + "px, " + randomIntFromInterval(-120, -200) + "px"}) scale(${finalScale/100}) rotate(${randomIntFromInterval(-40, 40)}deg);
        opacity: 0;
      }
    }
    `;
    document.head.appendChild(style);

    const particle = document.createElement("div");
    particle.classList.add(styles.particle);
    if (upgraded) {
      particle.classList.add(styles.particleUpgraded);
    }
    if (tap === 2) {
      particle.classList.add(styles.particleUpgradedMid);
    } else if (tap === 3) {
      particle.classList.add(styles.particleUpgradedHigh);
    }
    particle.innerHTML = `+${tap}`;
    particle.style.animation = `${animationID} 1s ease-out`;
    if (parent) {
      parent.append(particle);
    }
    particle.addEventListener("animationend", function() {
      particle.remove();
      style.remove();
    });
  }

const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}
