import styles from './Loader.module.scss'
import clsx from "clsx";

const Loader = ({ className, isDark = false, disabled = false }) => {
  return (
    <div className={clsx(styles.CircleWrapper, isDark ? styles.circleDark : "", className)}>
      <div className={clsx(styles.Circle, disabled && styles.disabled)}/>
      <div className={clsx(styles.Circle, disabled && styles.disabled)}/>
      <div className={clsx(styles.Circle, disabled && styles.disabled)}/>
      <div className={clsx(styles.Circle, disabled && styles.disabled)}/>
      <div className={clsx(styles.Circle, disabled && styles.disabled)}/>
      <div className={clsx(styles.Circle, disabled && styles.disabled)}/>
      <div className={clsx(styles.Circle, disabled && styles.disabled)}/>
      <div className={clsx(styles.Circle, disabled && styles.disabled)}/>
    </div>
  );
}

export default Loader