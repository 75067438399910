import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'

export const useCounter = (miningEraIntervalInSeconds, farmStartedAt) => {
  const [nextTimerTick, setNextTimerTick] = useState(null)

  useEffect(() => {
    if (!miningEraIntervalInSeconds) {
      return
    }

    const zeroTime = '00:00:00'

    const getElapsedTime = (farmStartedAt) => {
      const farmStartAdjusted = DateTime.fromISO(farmStartedAt).plus({ seconds: miningEraIntervalInSeconds })

      const diff = farmStartAdjusted.diffNow()

      if (diff.toMillis() > 0) {
        return diff.toFormat('hh:mm:ss')
      } else {
        return zeroTime
      }
    }

    const elapsedTime = getElapsedTime(farmStartedAt)

    setNextTimerTick(elapsedTime)

    let timerId

    if (elapsedTime !== zeroTime) {
      timerId = setInterval(() => {
        setNextTimerTick(getElapsedTime(farmStartedAt))
      }, 1000)
    }

    return () => {
      timerId && clearInterval(timerId)
    }
  }, [miningEraIntervalInSeconds, farmStartedAt])

  return {
    nextTimerTick,
  }
}
