import styles from './App.module.scss'

import {TelegramProvider} from './context/TelegramProvider'
import {AppProvider} from './context/AppProvider'
import Router from './components/Router/Router'
import './fonts/SF-Pro-Rounded-Medium.ttf'
import './fonts/SF-Pro-Rounded-Bold.ttf'
import './fonts/SF-Pro-Rounded-Semibold.ttf'

const App = () => {
  return (
    <TelegramProvider>
      <AppProvider>
        <Router/>
      </AppProvider>
    </TelegramProvider>
  )
}

export default App
