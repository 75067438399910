import styles from './QrCode.module.scss'
import Text from "../../base/Text/Text";

const QrCode = () => {

  return (
    <div className={styles.container}>
      <Text variant="h1" weight="bold" className={styles.text}>
        Open from your<br/>mobile device
      </Text>
      <div className={styles.qrContainer}>
        <span className={styles.shine}/>
        <img src="/img/qrCode.png" alt="" className={styles.qr}/>
      </div>
      <Text variant="h3" weight="bold" color="gradient">
        @drumtap_bot
      </Text>
    </div>
  )
}

export default QrCode