import {createContext, useContext, useEffect, useMemo, useState} from 'react'
import useApi from '../api/rest'
import {useTelegram} from './TelegramProvider'
import {DateTime} from "luxon";

const initialContext = {}

export const AppContext = createContext(initialContext)

const ALLOWED_PLATFORMS = ['android', 'ios']

export const AppProvider = ({ children }) => {
  const { webApp } = useTelegram()
  const [showScreen, setShowScreen] = useState('tap')
  const { getUserInfo } = useApi()
  const [ userInfo, setUserInfo ] = useState(null)
  const [ showSuccessModal, setShowSuccessModal ] = useState(false)
  const [ successModalData, setSuccessModalData ] = useState(null)
  const [isClaimFarmStarted, setIsClaimFarmStarted] = useState(false)
  const [tapsState, setTapsState] = useState({
    tapsLeft: null,
    tapsCount: 0,
    amount: 0,
    currentTapWindowFinishIn: null,
    currentTapWindowFinishAt: null
  })
  const [tapsTimeLeftInMillis, setTapsTimeLeftInMillis] = useState(null)

  const isPlatformAllowed = true;
  // useMemo(() => {
  //   return window.location.hostname === "drum.wigwam.app" ? webApp && webApp.platform && ALLOWED_PLATFORMS.includes(webApp.platform) : true;
  // }, [webApp])

  useEffect(() => {
    if (userInfo) {
      return
    }

    if (webApp && webApp.initData) {
      if (!isPlatformAllowed) {
        setShowScreen('code')
      } else {
        webApp.expand()
        webApp.onEvent('viewportChanged', () => {
          if (!webApp.isExpanded) {
            webApp.expand()
          }
        })
        getUserInfo().then((data) => {
          if (data.status === 'error') {
            // TODO error handling
          } else {
            setUserInfo(data)
          }
        })
      }
    }
  }, [webApp])

  useEffect(() => {
    let timerId

    if (webApp && webApp.initData && isPlatformAllowed) {
      timerId = setInterval(() => {
        getUserInfo()
          .then((data) => {
            if (data.status === 'error') {
              // TODO error handling
            } else {
              setUserInfo(data)
            }
          })
      }, 60000)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [webApp])

  useEffect(() => {
    if (userInfo !== null && userInfo && !userInfo.email) {
      setShowScreen('email')
    }
  }, [userInfo])

  useEffect(() => {
    let timerId;
    if (userInfo !== null) {
      if (tapsTimeLeftInMillis !== null && tapsTimeLeftInMillis > 0) {
        timerId = setInterval(() => {
          const duration = DateTime.fromMillis(tapsState.currentTapWindowFinishAt).diffNow().toMillis()
          setTapsTimeLeftInMillis(duration)
        }, 1000);
      }
      if (tapsTimeLeftInMillis !== null && tapsTimeLeftInMillis <= 0) {
        setTapsTimeLeftInMillis(null)
        setTapsState({
          ...tapsState,
          tapsLeft: null,
          tapsCount: 0,
          amount: 0,
          currentTapWindowFinishIn: null,
          currentTapWindowFinishAt: null
        })
        getUserInfo().then((data) => {
          if (data.status === 'error') {
            // TODO error handling
          } else {
            setUserInfo(data)
          }
        })
      }
    }

    return () => {
      clearInterval(timerId);
    };
  }, [tapsTimeLeftInMillis]);

  useEffect(() => {
    if (userInfo !== null) {
      const newTapsState = tapsState
      if (tapsState.tapsLeft === null || tapsState.tapsCount === 0) {
        newTapsState.tapsLeft = userInfo.availableTaps
      }
      if (userInfo.currentTapWindowFinishIn !== null && userInfo.currentTapWindowFinishAt !== null && tapsTimeLeftInMillis === null) {
        newTapsState.currentTapWindowFinishIn = userInfo.currentTapWindowFinishIn
        newTapsState.currentTapWindowFinishAt = userInfo.currentTapWindowFinishAt
        setTapsTimeLeftInMillis(userInfo.currentTapWindowFinishIn)
      }
      if (tapsState.currentTapWindowFinishAt < userInfo.currentTapWindowFinishAt) {
        newTapsState.currentTapWindowFinishIn = userInfo.currentTapWindowFinishIn
        newTapsState.currentTapWindowFinishAt = userInfo.currentTapWindowFinishAt
        setTapsTimeLeftInMillis(userInfo.currentTapWindowFinishIn)
      }
      setTapsState({
        ...tapsState,
        ...newTapsState
      })
    }
  }, [userInfo]);

  const value = useMemo(() => {
    return {
      showScreen,
      setShowScreen,
      userInfo,
      setUserInfo,
      showSuccessModal,
      setShowSuccessModal,
      successModalData,
      setSuccessModalData,
      tapsState,
      setTapsState,
      isClaimFarmStarted,
      setIsClaimFarmStarted,
      tapsTimeLeftInMillis
    }
  }, [showScreen, setShowScreen, userInfo, showSuccessModal, setShowSuccessModal, successModalData, setSuccessModalData, tapsState, setTapsState, tapsTimeLeftInMillis, isClaimFarmStarted])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useApp = () => useContext(AppContext)
