import { useApp } from '../../../../context/AppProvider'
import { useCounter } from './useCounter'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react';

export const useFarmScreen = () => {
  const { userInfo = {} } = useApp()
  const { nextTimerTick } = useCounter(
    userInfo?.miningEraIntervalInSeconds,
    userInfo?.farmStartedAt
  )
  const [farmedReward, setFarmedReward] = useState('0')
  const [isClaimAllowed, setIsClaimAllowed] = useState(false)

  const initialized = userInfo && Object.keys(userInfo).length
  const balance = userInfo?.balance || 0
  const miningEraIntervalInSeconds = userInfo?.miningEraIntervalInSeconds || 0
  const farmStartedAt = userInfo?.farmStartedAt
  const farmReward = userInfo?.farmReward
  const currencyPrecision = userInfo?.currencyPrecision

  function calculateFarmedReward(farmStartedAt, currentTime, farmingIntervalInSeconds, farmReward) {
    const farmStart = DateTime.fromISO(farmStartedAt)
    const current = DateTime.fromISO(currentTime)

    const totalTimeInSeconds = Math.ceil(current.diff(farmStart, 'seconds').seconds)

    const farmedReward = (totalTimeInSeconds * farmReward) / farmingIntervalInSeconds

    return {
      claimAllowed: totalTimeInSeconds >= parseInt(farmingIntervalInSeconds),
      reward: Math.min(farmedReward, farmReward).toFixed(currencyPrecision)
    }
  }

  useEffect(() => {
    const {reward, claimAllowed} = calculateFarmedReward(farmStartedAt, DateTime.now(), miningEraIntervalInSeconds, farmReward)

    setFarmedReward(reward)
    setIsClaimAllowed(claimAllowed)
  }, [nextTimerTick])

  return {
    initialized,
    balance,
    miningEraIntervalInSeconds,
    farmStartedAt,
    counter: nextTimerTick,
    farmedReward,
    isClaimAllowed,
  }
}
