import axios from 'axios'
import {useTelegram} from '../context/TelegramProvider'

const apiBaseUrl = window.location.hostname === "drum.wigwam.app"
  ? "https://drumapi.wigwam.app"
  : window.location.hostname === "drumbot-webapp.pages.dev"
    ? "https://staging-drumapi.wigwam.app"
    : (process.env.REACT_APP_API_HOST || "");

const restApiUrl = `${apiBaseUrl}/api`

const RestApi = axios.create({
  baseURL: restApiUrl,
})

const useApi = () => {
  const { webApp, user } = useTelegram()

  const getUserInfo = () => {
    return RestApi.post('/getUserInfo', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      platform: webApp?.platform,
      data: {},
    }).then((result) => {
      if (result.status === 200) {
        return result.data.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        error
      }
    })
  }

  const setUserEmail = (email) => {
    return RestApi.post('/setEmail', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {email}
    }).then((result) => {
      if (result.status === 200) {
        return result.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        error
      }
    })
  }

  const claimTap = (amount) => {
    return RestApi.post('/claimTap', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {amount}
    }).then((result) => {
      if (result.status === 200) {
        return result.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        error
      }
    })
  }

  const claimTaps = (taps, amount) => {
    return RestApi.post('/claimTaps', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {taps, amount}
    }).then((result) => {
      if (result.status === 200) {
        return result.data.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        error
      }
    })
  }

  const upgradeDrum = () => {
    return RestApi.post('/mintSuperDrum', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {}
    }).then((result) => {
      if (result.status === 200) {
        return result.data.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        error
      }
    })
  }

  const startFarm = () => {
    return RestApi.post('/startFarm', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {}
    }).then((result) => {
      if (result.status === 200) {
        return result.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        error
      }
    })
  }

  const claimFarm = () => {
    return RestApi.post('/claimFarm', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {}
    }).then((result) => {
      if (result.status === 200) {
        return result.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        error
      }
    })
  }

  const getChildren = () => {
    return RestApi.post('/getChildren', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {}
    }).then((result) => {
      if (result.status === 200) {
        return result.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        error
      }
    })
  }

  const claimFromChildren = () => {
    return RestApi.post('/claimFromChildren', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {}
    }).then((result) => {
      if (result.status === 200) {
        return result.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        error
      }
    })
  }

  const startTask = (taskId) => {
    return RestApi.post('/startTask', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {taskId}
    }).then((result) => {
      if (result.status === 200) {
        return result.data.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        error
      }
    })
  }

  const claimTask = (taskId) => {
    return RestApi.post('/claimTask', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {taskId}
    }).then((result) => {
      if (result.status === 200) {
        return result.data.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        error
      }
    })
  }

  const checkTask = (taskId, taskData) => {
    const reqData = {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {taskId}
    }
    if (taskData) {
      reqData.data.taskData = taskData
    }
    return RestApi.post('/checkTask', reqData).then((result) => {
      if (result.status === 200) {
        return result.data.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        reason: error.response.data.data.reason || 'Can’t verify, try again in several minutes'
      }
    })
  }

  const verifyEmail = (email) => {
    return RestApi.post('/verifyEmail', {
      devAuthData: user?.id,
      authData: webApp?.initData,
      data: {email}
    }).then((result) => {
      if (result.status === 200) {
        return result.data
      }
    }).catch((error) => {
      return {
        status: 'error',
        reason: error.response.data.data.reason || 'Something went wrong, try again in several minutes'
      }
    })
  }

  return {
    getUserInfo,
    setUserEmail,
    claimTap,
    claimTaps,
    upgradeDrum,
    claimFarm,
    startFarm,
    getChildren,
    claimFromChildren,
    startTask,
    claimTask,
    checkTask,
    verifyEmail
  }

}

export default useApi
