import styles from './ShineTokenName.module.scss'
import Text from '../Text/Text'
import { TOKEN_NAME } from '../../../constants'

const ShineTokenName = () => (
  <Text variant="h1" weight="bold" color="gradient" className={styles.shineWrapper}>
    {TOKEN_NAME}
    <span className={styles.shineToken1} />
    <span className={styles.shineToken2} />
    <span className={styles.shineToken3} />
  </Text>
)

export default ShineTokenName