import styles from './Text.module.scss'
import clsx from 'clsx'

const Text = ({
  tag: Tag = 'span',
  color = 'white',
  variant = 'textL',
  weight = 'normal',
  align = 'center',
  className,
  children
}) => {
  return <Tag className={clsx(styles.text, styles[color], styles[variant], styles[weight], styles[align], className && className)}>{children}</Tag>
}

export default Text
