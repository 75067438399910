import styles from './Email.module.scss'
import Text from '../../base/Text/Text'
import Button from '../../base/Button/Button'
import {useState} from 'react'
import useApi from '../../../api/rest'
import {useApp} from '../../../context/AppProvider'
import clsx from "clsx";
import Loader from "../../Loader/Loader";
import Icon from "../../Icon/Icon";
import { TOKEN_NAME } from '../../../constants'

const Email = () => {
  const { setUserEmail } = useApi()
  const { setShowScreen, setUserInfo } = useApp()
  const [value, setValue] = useState('')
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false)
  const [isEmailSaved, setIsEmailSaved] = useState(false)
  const [focused, setFocused] = useState(false);

  const handleInputChange = (e) => {
    setInvalidEmail(false)
    setValue(e.target.value)
  }

  const handleFocus = () => {
    setFocused(true)
    // setTimeout(() => window.scrollTo(0, document.body.scrollHeight));
  }

  const handleSubmit = (value) => {
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)

    if (!isValidEmail) {
      setInvalidEmail(true)
    } else {
      setInvalidEmail(false)
      setIsSubmittingEmail(true)
      setUserEmail(value).then((res) => {
        if (res.status === 'ok') {
          setUserInfo(res.data)
          setTimeout(() => {
            setIsSubmittingEmail(false)
            setIsEmailSaved(true)
            setTimeout(() => {
              setShowScreen('tap')
            }, 2000)
          }, 2000)
        }
        if (res.status === 'error') {
          setIsSubmittingEmail(false)
          setIsEmailSaved(false)
          // TODO add toast????
        }
      })
    }
  }

  return (
    <div className={clsx(styles.container, focused ? styles.containerFocus : "")}>
      <img src='/img/welcome.png' alt='' className={styles.image}/>
      <div className={styles.heading}>
        <Text variant="h2" weight="bold">
          Start tapping and generate <Text variant="h2" weight="bold" color="gradient">{TOKEN_NAME}</Text> tokens
        </Text>
        <Text variant="h3" weight="bold" color="gradient">
          I am ready to Start!
        </Text>
      </div>
      {isEmailSaved ?
        <div className={styles.emailSuccess}>
          <Icon name="success" width="40px" height="40px" className={styles.icon}/>
          <Text variant="h3" weight="bold">
            Email Approved!
          </Text>
        </div>
        :
        <form className={styles.formField}>
          <div className={styles.inputField}>
            <input id="email" value={value} type="email" onChange={handleInputChange} onFocus={handleFocus} onBlur={() => setFocused(false)} placeholder="Enter your email"
                   className={clsx(invalidEmail && styles.error)}/>
          </div>
          <Button type="submit" variant="filledSec" fullWidth onClick={() => handleSubmit(value)}
                  disabled={invalidEmail || isSubmittingEmail}>
            {isSubmittingEmail &&
              <Loader disabled={isSubmittingEmail}/>
            }
            <Text variant="textXL" weight="bold">
              {invalidEmail ? "Invalid Email" : "Start"}
            </Text>
          </Button>
        </form>
      }
    </div>
  )
}

export default Email